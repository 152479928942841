import React, { MouseEventHandler } from "react";
import InfoModal from "components/Modal/InfoModal";
import externalLinkIcon from "assets/external-link-white.svg";
import Image from "next/image";

interface BuyerDsrRequestProps {
  isVisible: boolean;
  toggle: MouseEventHandler;
}

const BuyerDsrRequest: React.FC<BuyerDsrRequestProps> = ({
  isVisible,
  toggle,
}) => {
  return (
    <InfoModal 
      isVisible={isVisible} 
      onClose={toggle}
      headerLabel="Exercising DSR Rights"
    >
      <div className="flex flex-col text-[#0A0A0A] max-w-[700px]">
        <div className="text-[#424242] text-sm">
          <span className="underline">Roposo Clout</span> recognises and affirms the pivotal role of Data Subject Requests (DSR) in upholding {"individuals'"} privacy rights. In accordance 
          with global data protection laws, any individual who wishes to exercise their rights, including but not limited to, of rectify, erase, restrict, 
          or object to the processing of their personal or sensitive information can make a DSR. In line with our unwavering commitment to protecting 
          the privacy of data subjects, <span className="underline">Roposo Clout</span> undertakes to respond to all data subject requests within the prescribed timeframe.<br /><br />

          If you are partnering with <span className="underline">Roposo Clout</span>, whereby you provide us with personal or sensitive personal information of data subjects, then, 
          you can use the <span className="underline">Roposo Clout Privacy Webform</span>. This webform enables you to submit the following DSR(s) regarding the information that is 
          processed by <span className="underline">Roposo Clout</span>:<br /><br />

          <ul className="list-disc ml-5">
            <li>Right to erasure;</li>
            <li>Right to opt-out of sell or share of personal information;</li>
            <li>Right to limit use/disclosure of sensitive personal information;</li>
            <li>Right to opt-out of processing.</li>
          </ul>

          <br />
          For more details and information about how we use and protect the personal or sensitive personal information, please review your {"'Agreement'"} with us.<br /><br />

          <span className="font-bold">How to raise buyer DSR request:</span><br /><br />
          <span className="font-bold">Step #1:</span>  Please{" "}
            <a 
              href="/assets/Sample-Sheet-for-DSR.xlsx"
              download
              className="text-[#0099FF] underline cursor-pointer"
            >
              download the sample sheet
            </a> 
            {" "}and fill the customer details in the sheet.<br /><br />
          <span className="font-bold">Step #2:</span>  Click on Create Request, fill in the form and upload the sheet.
        </div>
        <div className="flex mt-6">
          <a
            id="dsr-button"
            href="https://privacyportal-in.onetrust.com/webform/95b92ab8-599a-42af-a553-81bd40d6ce4f/94e34b82-4dff-4c15-8aac-92e17659d525"
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-1 rounded-sm bg-black text-[14px] text-white px-5 py-2 font-bold"
          >
            <div className="w-[24px] h-[24px]">
              <Image
                src={externalLinkIcon}
                alt="externalLinkIcon"
                height={24}
                width={24}
              />
            </div>
            <span>Create Request</span>
          </a>
        </div>
      </div>
    </InfoModal>
  );
};

export default BuyerDsrRequest;
